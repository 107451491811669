<template>
  <div>
    <template>
      <div class="flex_box">
        <div class="left">
          <!-- 中文 -->
          <el-menu
            v-if="$store.state.show"
            :default-active="this.$route.path"
            class="el-menu-vertical-demo"
            text-color="#222"
            active-text-color="#4C8CF5"
            router
            :unique-opened="true"
          >
            <h3>拓幻科技TiSDK</h3>
            <el-submenu index="1">
              <template slot="title">
                <span>新手指南</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/sdk"
                  >SDK与Tools</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <span>集成开发</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/integrated"
                  >秘钥Key</el-menu-item
                >
                <el-menu-item index="/developer/navPage/integrated/ios"
                  >iOS平台</el-menu-item
                >
                <el-menu-item index="/developer/navPage/integrated/android"
                  >Android平台</el-menu-item
                >
                <el-menu-item index="/developer/navPage/integrated/third"
                  >第三方集成方案</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <span>API中心</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/apiCenter"
                  >iOS接入</el-menu-item
                >
                <el-menu-item index="/developer/navPage/apiCenter/androidAccess"
                  >Android接入</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <span>贴纸Tools</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/sticker/tools"
                  >Tools使用文档</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">
                <span>常见问题</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/common"
                  >升级新ui</el-menu-item
                >
                <el-menu-item index="/developer/navPage/common/iosRelated"
                  >iOS相关</el-menu-item
                >
                <el-menu-item index="/developer/navPage/common/andRelated"
                  >Android相关</el-menu-item
                >
                <el-menu-item index="/developer/navPage/common/thirdParty"
                  >第三方集成相关</el-menu-item
                >
                <el-menu-item index="/developer/navPage/common/stickerTools"
                  >贴纸Tools相关</el-menu-item
                >
                <el-menu-item index="/developer/navPage/common/other"
                  >其他问题</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="6">
              <template slot="title">
                <span>更新说明</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/update"
                  >Android端</el-menu-item
                >
                <el-menu-item index="/developer/navPage/update/iosUpdate"
                  >IOS端</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="7">
              <template slot="title">
                <span>合规使用说明</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/compliance/guide"
                  >合规使用说明</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>

          </el-menu>
          <!-- 英文 -->
          <el-menu
            v-else
            :default-active="this.$route.path"
            class="el-menu-vertical-demo"
            text-color="#222"
            active-text-color="#4C8CF5"
            router
            :unique-opened="true"
          >
            <h3>Tillusory TiSDK</h3>
            <el-submenu index="1">
              <template slot="title">
                <span>Guide</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/sdk"
                  >SDK and Tools</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <span>Integrated Development</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/integrated/key"
                  >Key</el-menu-item
                >
                <el-menu-item index="/developer/navPage/integrated/ios"
                  >iOS Platform
                </el-menu-item>
                <el-menu-item index="/developer/navPage/integrated/android"
                  >Android Platform</el-menu-item
                >
                <el-menu-item index="/developer/navPage/integrated/third"
                  >Third-party integration scheme</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <span>API Center</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/apiCenter/iosAccess"
                  >iOS access</el-menu-item
                >
                <el-menu-item index="/developer/navPage/apiCenter/androidAccess"
                  >Android access
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <span>Sticker tools</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/sticker/tools"
                  >Tools usage document
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">
                <span>Common Problem</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/common/upGrade"
                  >Upgrade New UI</el-menu-item
                >
                <el-menu-item index="/developer/navPage/common/iosRelated"
                  >IOS related</el-menu-item
                >
                <el-menu-item index="/developer/navPage/common/andRelated"
                  >Android related
                </el-menu-item>
                <el-menu-item index="/developer/navPage/common/thirdParty"
                  >Third-party integration related
                </el-menu-item>
                <el-menu-item index="/developer/navPage/common/stickerTools"
                  >Sticker Tools Related</el-menu-item
                >
                <el-menu-item index="/developer/navPage/common/other"
                  >Other questions</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="6">
              <template slot="title">
                <span>Update description</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/update/andUpdate"
                  >Android</el-menu-item
                >
                <el-menu-item index="/developer/navPage/update/iosUpdate"
                  >IOS</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            
            <el-submenu index="7">
              <template slot="title">
                <span>合规使用说明</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/developer/navPage/compliance/guide"
                  >合规使用说明</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>

          </el-menu>
        </div>
        <div class="right">
          <!-- 面包屑导航部分 -->
          <div class="nav_div">
            <BreadNav></BreadNav
            ><span @click="change"
              ><span :class="this.$store.state.show ? 'sel' : ''">中文</span> |
              <span :class="!this.$store.state.show ? 'sel' : ''"
                >EN</span
              ></span
            >
          </div>
          <router-view></router-view>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import BreadNav from "@/components/BreadNav/index"; //面包屑导航
export default {
  components: { BreadNav },
  data() {
    return {};
  },
  methods: {
    change() {
      this.$store.commit("change");
    },
  },
};
</script>
<style lang="scss" scoped>
.flex_box {
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  padding: 40px 0;

  .left {
    width: 17.3%;
    height: 414px;
    margin-right: 40px;
    background: #ffffff;
    .el-menu-vertical-demo {
      h3 {
        font-size: 16px;
        font-weight: 500;
        height: 73px;
        line-height: 70px;
        padding-left: 20px;

        color: #222222;
      }
    }
    .is-opened .el-submenu__title span {
      color: #4c8cf5;
    }
    .is-active {
      background: #f4f7fe;
    }
    .el-menu-item-group li.is-active {
      border-right: 2px solid #4c8cf5;
    }
  }
  .right {
    width: 54%;
    height: 748px;
    padding: 1px 36px 20px;
    background: #ffffff;
    overflow-y: scroll;
    .nav_div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
      }
      .sel {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>